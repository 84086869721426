import React from 'react';
import styles from './ProblemSection.module.css';

const ProblemSection = () => {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>The Problem with Online Reviews</h2>
      <div className={styles.problemsContainer}>
        <div className={styles.problem}>
          <h3 className={styles.problemTitle}>Fake Reviews</h3>
          <p className={styles.problemDescription}>
            Online reviews are often plagued by fake reviews, making it difficult for customers to trust the authenticity of the reviews.
          </p>
        </div>
        <div className={styles.problem}>
          <h3 className={styles.problemTitle}>Biased Dispute Resolutions</h3>
          <p className={styles.problemDescription}>
            Most review platforms are biased on their dispute resolutions for fake reviews, undermining the reputation of businesses.
          </p>
        </div>
        <div className={styles.problem}>
          <h3 className={styles.problemTitle}>Lack of Transparency</h3>
          <p className={styles.problemDescription}>
            The process of resolving disputes and managing reviews is often opaque, leading to a lack of trust in the review ecosystem.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ProblemSection;