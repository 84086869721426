import React, { useState, useEffect } from 'react';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import './widget.css';

const base = {
    chainId: `0x${parseInt(84532).toString(16)}`,
    rpcUrls: ['https://sepolia.base.org/'],
};

const ReviewsWidget = ({ contractAddress, contractABI, businessId }) => {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const provider = new JsonRpcProvider(base.rpcUrls[0]);
                const contract = new ethers.Contract(contractAddress, contractABI, provider);

                const fetchedReviews = await contract.getReviewsByBusiness(0);
                setReviews(fetchedReviews);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };

        fetchReviews();
    }, [contractAddress, contractABI, businessId]);

    // Function to generate star ratings
    const renderStars = (rating) => {
        let stars = '';
        for (let i = 0; i < 5; i++) {
            stars += i < rating ? '★' : '☆';
        }
        return stars;
    };

    return (
        <div className="widget-container">
            <div className="widget-header">
                <h2 className="widget-title">What our community says</h2>
            </div>
            {reviews.length > 0 ? (
                reviews.map((review, index) => (
                    <div key={index} className="review-card">
                        <div className="review-rating">
                            {renderStars(review.rating)}
                        </div>
                        <div className="review-text">{review.reviewText}</div>
                    </div>
                ))
            ) : (
                <p>No reviews available.</p>
            )}
        </div>
    );
};

export default ReviewsWidget;
