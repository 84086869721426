import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import ReviewsWidget from './components/Widget';
import contractABI from './contractABI.json'; // The ABI should be in a JSON file

const contractAddress = '0x20dC73e872A9E3a2b6D671f18Ae4f5FFDE6bAe42'; // Replace with your contract's address



function App() {
  useEffect(() => {
    // Google Analytics 4 tracking code
    const ga4TrackingId = `${process.env.REACT_APP_GA4_MEASUREMENT}`; // Replace with your actual measurement ID

    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${ga4TrackingId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize the Google Analytics tracking
    window.dataLayer = window.dataLayer || [];
    function gtag(...args) {
      window.dataLayer.push(args);
    }
    gtag('js', new Date());
    gtag('config', ga4TrackingId);

    // Initialize Mixpanel
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {debug: true, track_pageview: true, persistence: 'localStorage'});

  }, []);

  return (
    <div className="App">
      <Header />
      <Main />
     </div>
  );
}

export default App;