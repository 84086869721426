import React from 'react';
import './HowItWorks.css';
import exampleImage1 from '../assets/embed.png';
import exampleImage2 from '../assets/social-communities.png';
import exampleImage3 from '../assets/collectWidget.png';

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2>How it works</h2>
      <div className="step">
        <div className="step-number">1</div>
        <div className="step-description">
          <h3>Add our widget to your website</h3>
          <p>Easily integrate our widget into your website's code</p>
        </div>
        <div className="step-example">
          <img src={exampleImage1} alt="Example 1" />
        </div>
      </div>
      <div className="step">
        <div className="step-example2">
          <img src={exampleImage2} alt="Example 2" />
        </div>
        <div className="step-number">2</div>
        <div className="step-description">
            
          <h3>Share with your community</h3>
          <p>Let your community know they can leave their feedback about your project</p>
        </div>
      </div>
      <div className="step">
        <div className="step-number">3</div>
        <div className="step-description">
          <h3>Collect and showcase reviews from them</h3>
          <p>All the reviews stay on-chain for full transparency and decentralization</p>
        </div>
        <div className="step-example">
          <img src={exampleImage3} alt="Example 3" />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;