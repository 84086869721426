import React from 'react';
import logo1 from '../logoPartnerChain.png'; // Replace with your actual logo file
import logo2 from '../logoStartupLisboa.png'; // Replace with your actual logo file

const LogoSection = () => {
  return (
    <div className="logo-section">
      <h3>We are backed by</h3>
      <div className="logos">
        <img className="logo1" src={logo1} alt="Logo PartnerChain" />
        <img className="logo2" src={logo2} alt="Logo Startup Lisboa" />
      </div>
    </div>
  );
};

export default LogoSection;