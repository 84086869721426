import React from 'react';
import DemoImage from '../demo.png'; 

const Demo = () => {
  return (
    <div className="demo-section">
        <img className="demo" src={DemoImage} alt="Product Demo" />
    </div>
  );
};

export default Demo;