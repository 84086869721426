import React, { useState, useEffect, useRef } from 'react';
import mixpanel from 'mixpanel-browser';
import './Popup.css';

function Popup({ onClose }) {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [fundingStage, setFundingStage] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState(''); // New state for email validation error message
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const validateEmail = (email) => {
    // List of free email domains to exclude
    const freeEmailDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
    const emailDomain = email.split('@')[1];

    return !freeEmailDomains.includes(emailDomain);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Please use a company or professional email.');
      return; // Stop form submission if validation fails
    }

    // Mixpanel tracking
    mixpanel.track("Form Submission", {
      email: email,
      role: role,
      fundingStage: fundingStage,
      companyType: companyType
    });

    const apiUrl = `https://api.airtable.com/v0/appbmECNj8lNFC1l5/Waitlist`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
        },
        body: JSON.stringify({
          fields: {
            Email: email,
            Role: role,
            FundingStage: fundingStage,
            CompanyType: companyType,
          },
        }),
      });

      if (response.ok) {
        setMessage('Thank you! See you soon!');
        setEmailError('');
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setMessage('Failed to submit. Please try again.');
        setEmailError('');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again later.');
      setEmailError('');
    }
  };

  return (
    <div className="popup" ref={popupRef}>
      <div className="popup-content">
        {message ? (
          <h1 className='title'>{message}</h1>
        ) : (
          <>
            <h1 className='title'>Be the first to try it!</h1>
            <h2>Apply to our waiting list</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter professional email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(''); // Clear error message when user modifies email
                }}
                required
              />
             
              <input
                type="text"
                placeholder="Your role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              />
              <select
                value={fundingStage}
                onChange={(e) => setFundingStage(e.target.value)}
                required
              >
                <option value="">Funding</option>
                <option value="None">None</option>
                <option value="Private Sale">Private Sale</option>
                <option value="ICO">ICO</option>
                <option value="Pre-Seed">Pre-Seed</option>
                <option value="Seed">Seed</option>
                <option value="Series A">Series A</option>
              </select>
              <select
                value={companyType}
                onChange={(e) => setCompanyType(e.target.value)}
                required
              >
                <option value="">Sector</option>
                <option value="Web3 Development">Web3 Development</option>
                <option value="Web3 Consulting">Web3 Consulting</option>
                <option value="Blockchain Analytics">Blockchain Analytics</option>
                <option value="NFT Marketplace">NFT Marketplace</option>
                <option value="DeFi Platform">DeFi Platform</option>
                <option value="Security">Security</option>
                <option value="Real World Assets (RWA)">Real World Assets (RWA)</option>
                <option value="Metaverse Development">Metaverse Development</option>
                <option value="NFT Projects">NFT Projects</option>
                <option value="Cryptocurrency Exchange">Cryptocurrency Exchange</option>
                <option value="DAO (Decentralized Autonomous Organization)">DAO (Decentralized Autonomous Organization)</option>
                <option value="Blockchain Gaming">Blockchain Gaming</option>
                <option value="Digital Identity Solutions">Digital Identity Solutions</option>
                <option value="Supply Chain Management">Supply Chain Management</option>
                <option value="Legal & Compliance">Legal & Compliance</option>
                <option value="Financial Services">Financial Services</option>
                <option value="Education and Training">Education and Training</option>
                <option value="Content Creation & Management">Content Creation & Management</option>
                <option value="Social Media Platforms">Social Media Platforms</option>
                <option value="Agency (Web3 Focused)">Agency (Web3 Focused)</option>
              </select>

              <button type="submit">Submit</button>
            </form>
            <button className="close-btn" onClick={onClose}>
              ✖
            </button>
          </>
        )}
         {emailError && <div style={{ color: 'red', marginTop: '10px' }}>{emailError}</div>}
      </div>
      
    </div>
  );
}

export default Popup;
