import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import logo from '../logo.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    // Google Analytics 4 event tracking
    window.gtag('event', 'click', {
      'event_category': 'Link',
      'event_label': 'How it works',
    });

    // Mixpanel event tracking
    mixpanel.track('Link Clicked', { link: 'How it works' });
  };

  return (
    <header className="App-header">
      <nav className={`menu ${isOpen ? 'open' : ''}`}>
      <ul>
          <li>
            <a
              href="https://trustled.xyz"
              rel="noopener noreferrer"
              onClick={handleLinkClick}
            >
              <img className='logo' src={logo} alt='logo'></img>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a
              href="https://trust-partnerchain.gitbook.io/whitepaper"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLinkClick}
            >
              How it works
            </a>
          </li>
        </ul>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </header>
  );
}

export default Header;