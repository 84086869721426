import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import Popup from './Popup';
import LogoSection from './LogoSection';
import Demo from './Demo';
import ProblemSection from './ProblemSection';
import SolutionSection from './SolutionSection';
import HowItWorks from './How';

function Main() {
  const [showPopup, setShowPopup] = useState(false);

  const handleTryNowClick = () => {
    setShowPopup(true);

    // Google Analytics 4 event tracking
    window.gtag('event', 'click', {
      'event_category': 'Button',
      'event_label': 'Try it now',
    });

    // Mixpanel event tracking
    mixpanel.track('Button Clicked', { button: 'Try it now' });
  };

  return (
    <main>
      <h1>
        Do you <span>trust</span> online reviews?
      </h1>
      <h2>Avoid fake reviews about your business. Prove your reputation to your community.</h2>
      <button onClick={handleTryNowClick}>Try it now</button>
      {showPopup && <Popup onClose={() => setShowPopup(false)} />}
      <Demo/>
      <HowItWorks />
      <ProblemSection />
      <SolutionSection />
      <LogoSection />
    </main>
  );
}

export default Main;