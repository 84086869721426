import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import Popup from './Popup';
import styles from './SolutionSection.module.css';

const SolutionSection = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleTryNowClick = () => {
    setShowPopup(true);
    mixpanel.track('Button Clicked', { button: 'Try it now 2' });
  };

  return (
    <section className={styles.container}>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>
          <h2 className={styles.title}>Why us?</h2>
          <h3 className={styles.subtitle}>We change the way people trust your project.</h3>
        
          <button className={styles.solutionButton} onClick={handleTryNowClick}>
        Try it now
      </button>
        </div>
      </div>
      <div className={styles.solutionContainer}>
        <div className={styles.solution}>
          <div className={styles.solutionHeader}>
            <div className={styles.solutionIcon}>🔒</div>
            <h3 className={styles.solutionTitle}>Decentralized Reviews</h3>
          </div>
          <p className={styles.solutionDescription}>
            We use blockchain technology to create a decentralized review platform, ensuring transparency and eliminating the need for a centralized authority.
          </p>
        </div>
        <div className={styles.solution}>
          <div className={styles.solutionHeader}>
            <div className={styles.solutionIcon}>🛡️</div>
            <h3 className={styles.solutionTitle}>Incentives and Rewards</h3>
          </div>
          <p className={styles.solutionDescription}>
            Our incentives mechanism ensures that users are rewarded for leaving true feedback and penalized for fake reviews.
          </p>
        </div>
        <div className={styles.solution}>
          <div className={styles.solutionHeader}>
            <div className={styles.solutionIcon}>🌐</div>
            <h3 className={styles.solutionTitle}>Prevent Fake Reviews</h3>
          </div>
          <p className={styles.solutionDescription}>
            A DAO arbitration protocol allows to resolve disputes in a fair and transparent manner, ensuring unbiased review management.
          </p>
        </div>
      </div>
     
      {showPopup && <Popup onClose={() => setShowPopup(false)} />}
    </section>
  );
};

export default SolutionSection;